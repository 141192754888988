import React from 'react';
import ReactDOM from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { QueryClientProvider } from 'react-query';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { ToastContainer } from 'react-toastify';

import App from './app/app';
import ApolloClientProvider from './libs/apollo-client';
import CustomEvergreenTheme from './libs/custom-evergreen-theme';
import UserIpAddressProvider from './providers/user-ipaddress';

import GlobalStyles from './components/global-styles/global-styles';
import ErrorStateMsg from './components/error-state-msg/error-state-msg';

import './helpers/i18n';
import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/custom-datepicker.css';
import './assets/css/stripe-card-form.css';

import queryClient from './libs/react-query-client';
import clientStorage from './libs/client-storage';
import SuperAffiliateProvider from './providers/super-affiliate';
import { GoogleOAuthProvider } from '@react-oauth/google';
import WidgetProtectedRoute from './routes/widget-protected/widget-protected';
import { CookieProvider } from './providers/cookie-policy';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const widgetContainerId = 'ticketsir-widget';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  init: (config) => {
    return {
      render: (args) => {
        const s = document.createElement('div');
        s.id = widgetContainerId;
        document.body.appendChild(s);

        ReactDOM.render(
          <React.StrictMode>
            <ErrorBoundary
              FallbackComponent={ErrorStateMsg}
              onError={(event) => {
                const error = event.originalError;

                if (error instanceof Error) {
                  const chunkFailedMessage = /Loading chunk [\d]+ failed/;

                  if (
                    error?.message &&
                    chunkFailedMessage.test(error.message)
                  ) {
                    if (!clientStorage.getWithExpiry('chunk_failed')) {
                      clientStorage.setWithExpiry(
                        'chunk_failed',
                        'true',
                        10000
                      );
                      window.location.reload();
                    }
                  }
                }
              }}
            >
              <SuperAffiliateProvider
                credentials={{
                  domainName: config?.domainName || args?.domainName,
                  apiKey: config?.apiKey || args?.apiKey,
                }}
              >
                <ApolloClientProvider>
                  <WidgetProtectedRoute
                    component={
                      <CookieProvider>
                        <QueryClientProvider client={queryClient}>
                          <UserIpAddressProvider>
                            <CustomEvergreenTheme>
                              <GlobalStyles />
                              <ToastContainer />
                              <Elements stripe={stripePromise}>
                                <GoogleOAuthProvider
                                  clientId={
                                    process.env.REACT_APP_GOOGLE_CLIENT_ID
                                  }
                                >
                                  <HttpsRedirect>
                                    <App />
                                  </HttpsRedirect>
                                </GoogleOAuthProvider>
                              </Elements>
                            </CustomEvergreenTheme>
                          </UserIpAddressProvider>
                        </QueryClientProvider>
                      </CookieProvider>
                    }
                  />
                </ApolloClientProvider>
              </SuperAffiliateProvider>
            </ErrorBoundary>
          </React.StrictMode>,
          document.getElementById(widgetContainerId)
        );
      },
      unmount: () => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById(widgetContainerId)
        );
      },
    };
  },
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
